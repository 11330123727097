<template>
    <div class="nav_bar">
        <div class="wrap_box">
            <div class="nav_bar_l">
                <img class="logo" src="@/assets/images/logo.png" alt="">
                <div class="logo_r">
                    <p>商用车</p>
                    <p>智慧管理先驱者</p>
                </div>
            </div>
            <div class="nav_bar_r">
                <ul class="nav_bar_r_l">
                    <!-- <li>
                        <i class="icon"></i>
                        <p>商家入驻</p>
                    </li> -->
                    <li>
                        <a class="a" target="_blank" href="https://gk.xiaokayun.net/">
                            <i class="icon"></i>
                            <p>登录</p>
                        </a>
                    </li>
                </ul>
                <ul class="nav_bar_r_r">
                    <li>
                        <a href="https://xiaokaguanche.oss-cn-guangzhou.aliyuncs.com/app/download/xiaoka_release.apk">APP下载</a>
                    </li>
                    <li>
                        <a href="#submit">免费试用</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.nav_bar{
    width: 100%;
    height: 70px;
    background: #fff;
    .wrap_box{
        display: flex;
        justify-content: space-between;
    }
    .nav_bar_l{
        display: flex;
        align-items: center;
        height: 70px;
        .logo{
            height: 42px;
        }
        .logo_r{
            margin-left: 24px;
            height: 42px;
            border-left: 1px solid #DDDDDD;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            p{
                font-size: 14px;
                color: #999999;
                padding-left: 20px;
            }
        }
    }
    .nav_bar_r{
        display: flex;
        .nav_bar_r_l{
            display: flex;
            li{
                .a{
                    display: flex;
                    align-items: center;
                    height: 70px;
                }
                .icon{
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                }
                &:nth-of-type(1) .icon{
                    background: url('~@/assets/images/shop_icon.png') no-repeat center;
                }
                &:nth-of-type(2) .icon{
                    background: url('~@/assets/images/login_icon.png') no-repeat center;
                }
                p{
                    font-size: 14px;
                    color: #333333;
                    margin-left: 6px;
                }
                &:last-child{
                    margin-left: 30px;
                }
            }
        }
        .nav_bar_r_r{
            display: flex;
            align-items: center;
            margin-left: 40px;
            li{
                width: 113px;
                height: 32px;
                border-radius: 5px;
                border: 1px solid #3075FF;
                font-size: 14px;

                line-height: 32px;
                text-align: center;
                a{
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: #333333;
                }
                &:nth-of-type(2){
                    margin-left: 25px;
                    background: #3075FF;
                    color: #fff;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>
