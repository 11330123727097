<template>
    <div class="footer">
        <div class="wrap_box">
            <p class="info">广州汇博信息技术有限公司 版权所有 <a href="https://beian.miit.gov.cn/">粤ICP备18114771号</a></p>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.footer{
    .info{
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        color: #949a9e;
        a{
            color: #949a9e;
        }
    }
}
</style>
