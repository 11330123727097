import axios from 'axios'

// axios.defaults.withCredentials = true

const axiosInstance = axios.create({
//   baseURL: window.SITE_CONFIG.serverUrl,
  headers: { 'content-type': 'application/json;charset=UTF-8' },
  // baseURL: process.env.NODE_ENV === 'development' ? '/' : 'https://app.xiaokayun.net/',
  baseURL: '/',
  timeout: 300000,
  withCredentials: true
})

// http request 拦截器
axiosInstance.interceptors.request.use(
  config => {
    return config
  },
  err => {
    return Promise.reject(err)
  })

// http response 拦截器

axiosInstance.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response) {
      const msg = '系统出现错误，请重试'
      return Promise.reject(msg)
    }
  })

export default axiosInstance
