import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'wowjs/css/libs/animate.css'
import Axios from './http/index.js'
import VueAxios from 'vue-axios'
import element from 'element-plus'
import 'element-plus/dist/index.css'
createApp(App).use(store).use(router).use(VueAxios, Axios).use(element).mount('#app')
