<template>
  <nav-bar/>
  <router-view/>
  <Footer/>
</template>
<script>
import NavBar from './components/nav.vue'
import Footer from './components/footer.vue'
export default {
  components: {
    NavBar,
    Footer
  }
}
</script>
<style lang="scss">
*{
  padding:0;
  margin:0;
}
a{
  text-decoration: none;
}
input{
  outline: none;
}
li{
  list-style: none;
}
.wrap_box{
  width: 1200px;
  margin: 0 auto;
}
.bt{
  text-align: center;
  .title{
    font-size: 40px;
    font-weight: bold;
    color: #444444;
  }
  .desc{
    padding-top: 20px;
    font-size: 26px;
    color: #999999;
  }
}
.swiper-pagination{
  bottom:103px !important;
}
.swiper-pagination-bullet-active{
  background: #fff !important;
}
</style>
